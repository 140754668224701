import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    InputAdornment,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLineContext } from "../../hooks/useLineContext";
import { baseURL } from "../../api/url";
import { utils, writeFileXLSX } from "xlsx";
import { DatePicker } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import RedButton from "../../components/ui/RedButton";
import InputField from "../../components/ui/InputField";
import DialogInfo from "../../components/ui/DialogInfo";
import mainBgWhite from "../../assets/images/bg/main-bg-white.png";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import locale from "antd/es/date-picker/locale/th_TH";
import DialogSuccess from "../../components/ui/DialogSuccess";
import DialogLoading from "../../components/ui/DialogLoading";

const customStyles = {
    headRow: {
        style: {
            fontSize: ".75rem",
            color: "white",
            background: "linear-gradient(0deg, #365CA0, #264373, #152741)",
        },
    },
    headCells: {
        style: {
            justifyContent: "center",
            borderLeft: "1px solid white",
        },
    },
    rows: {
        style: {
            background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
            cursor: "pointer",
            "&:not(:last-of-type)": {
                borderBottomColor: "white",
            },
        },
    },
    cells: {
        style: {
            padding: ".5rem",
            "& div:first-child": {
                whiteSpace: "pre-wrap !important",
                overflow: "auto !important",
                textOverflow: "unset !important",
            },
        },
    },
};

const customStyles2 = {
    ...customStyles,
    cells: {
        style: {
            padding: "0",
            "& div:first-child": {
                whiteSpace: "pre-wrap !important",
                overflow: "auto !important",
                textOverflow: "unset !important",
            },
        },
    },
};

const customStyles3 = {
    ...customStyles,
    headCells: {
        style: {
            justifyContent: "center",
            "&:not(:first-child)": {
                borderLeft: "1px solid white",
            },
        },
    },
};

const { RangePicker } = DatePicker;

const AdminPage = () => {
    const { liffToken } = useLineContext();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [isLoading3, setIsLoading3] = useState(true);
    const [tabActive, setTabActive] = useState(1);
    const [term, setTerm] = useState("");
    const [companyList, setCompanyList] = useState([]);
    const [companyFilterList, setCompanyFilterList] = useState([]);

    const [term2, setTerm2] = useState("");
    const [redeemHistoryList, setRedeemHistoryList] = useState([]);
    const [redeemHistoryListExcel, setRedeemHistoryListExcel] = useState([]);
    const [rangeDate, setRangeDate] = useState([null, null]);
    const [confirmApproveDialog, setConfirmApproveDialog] = useState(false);
    const [approveErrorDialog, setApproveErrorDialog] = useState(false);
    const [approveSuccessDialog, setApproveSuccessDialog] = useState(false);
    const [verifyCode, setVerifyCode] = useState("");
    const [verifyCodeError, setVerifyCodeError] = useState("");
    const [approveData, setApproveData] = useState([]);
    const inputApproveRef = useRef();

    const [tabActive3_in, setTabActive3_in] = useState(1);
    const [term3, setTerm3] = useState("");
    const [isSearchEvaluation, setIsSearchEvaluation] = useState(false);
    const [isSearchLineUser, setIsSearchLineUser] = useState(false);
    const [listEvaluation, setListEvaluation] = useState([]);
    const [listEvaluationFiltered, setListEvaluationFiltered] = useState([]);
    const [listLineUser, setListLineUser] = useState([]);
    const [listLineUserFiltered, setListLineUserFiltered] = useState([]);
    const [sendEvaluateLoading, setSendEvaluateLoading] = useState(false);
    const [sendEvaluateSuccess, setSendEvaluateSuccess] = useState(false);

    const columns = [
        {
            name: "รหัสบริษัท",
            width: "120px",
            sortable: true,
            selector: (row) => row.code || "-",
            style: {
                justifyContent: "center",
            },
        },
        {
            name: "ชื่อบริษัท",
            minWidth: "200px",
            sortable: true,
            selector: (row) => row.company_name || "-",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "ชื่อผู้มีอำนาจ",
            minWidth: "200px",
            sortable: true,
            selector: (row) => row.people_name || "-",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "คะแนนสะสม",
            minWidth: "80px",
            sortable: true,
            selector: (row) => parseFloat(row.accumulated_points.toFixed(2)) || "0",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "เกณฑ์การให้คะแนน",
            minWidth: "80px",
            sortable: true,
            selector: (row) => parseFloat(row.spending_criteria.toFixed(2)) || "0",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "ยอดที่ใช้ไปล่าสุด",
            minWidth: "80px",
            sortable: true,
            selector: (row) => parseFloat(row.spending_amount.toFixed(2)) || "0",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "วันที่สมัคร",
            minWidth: "80px",
            sortable: true,
            selector: (row) => (row.register_timestamp ? row.register_timestamp : "-"),
            format: (row) => (row.register_timestamp ? dayjs(row.register_timestamp).format("DD/MM/YYYY") : "-"),
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
    ];

    const columns2 = [
        {
            name: "วันเวลา",
            width: "180px",
            sortable: true,
            cell: (row) =>
                row[0].create_timestamp ? dayjs(row[0].create_timestamp).format("DD/MM/YYYY HH:mm:ss") : "-",
            style: {
                padding: "0 .5rem",
            },
        },
        {
            name: "รหัสบริษัท",
            width: "150px",
            sortable: true,
            cell: (row) => row[0].code || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "ชื่อบริษัท",
            width: "220px",
            sortable: true,
            cell: (row) => row[0].company_name || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "รางวัลที่แลก",
            minWidth: "150px",
            sortable: false,
            cell: (row) =>
                row.map((item, idx) => (
                    <Box
                        key={Math.random()}
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                            borderBottom: row.length - 1 === idx ? "none" : "1px solid white",
                            padding: "0 .5rem",
                        }}
                    >
                        {item.reward_name}
                    </Box>
                )),
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "จำนวน",
            width: "120px",
            sortable: false,
            cell: (row) =>
                row.map((item, idx) => (
                    <Box
                        key={Math.random()}
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                            borderBottom: row.length - 1 === idx ? "none" : "1px solid white",
                            padding: "0 .5rem",
                        }}
                    >
                        {item.reward_quantity}
                    </Box>
                )),
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "คะแนนที่ใช้",
            width: "150px",
            sortable: false,
            cell: (row) =>
                row.map((item, idx) => (
                    <Box
                        key={Math.random()}
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                            borderBottom: row.length - 1 === idx ? "none" : "1px solid white",
                            padding: "0 .5rem",
                        }}
                    >
                        {item.reward_point}
                    </Box>
                )),
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "Order ID",
            width: "150px",
            sortable: true,
            button: true,
            cell: (row) =>
                !row[0].order_id ? (
                    <Button
                        onClick={() => onApproveClick(row)}
                        sx={{
                            color: "white",
                            backgroundColor: "#002B38",
                            padding: ".25rem 1rem",
                            "&:hover": { color: "white", backgroundColor: "#002B38", opacity: ".85" },
                        }}
                    >
                        อนุมัติ
                    </Button>
                ) : (
                    row[0].order_id
                ),
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "ชื่อผู้รับ",
            width: "180px",
            sortable: false,
            cell: (row) => row[0].name,
            style: {
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
    ];

    const columns3 = [
        {
            name: "ชื่อผู้รับ",
            width: "220px",
            sortable: true,
            cell: (row) => row.user_info.displayName || row.user_info.lineuserid || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
            },
        },
        {
            name: "ชื่อผู้ส่ง",
            width: "220px",
            sortable: true,
            cell: (row) => row.admin_info.name || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "คะแนน",
            width: "140px",
            sortable: true,
            cell: (row) => row.rating || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "ข้อเสนอแนะ",
            sortable: true,
            cell: (row) => row.comment || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
    ];

    const onRowClicked = (row) => {
        navigate(`/admin/${row.code}`);
    };

    const onFilterCompanyList = (event) => {
        const value = event.target.value;
        const newCompanyList = companyList.filter((item) => {
            return (
                item.company_name.toLowerCase().includes(value.toLowerCase()) ||
                item.code.toLowerCase().includes(value.toLowerCase())
            );
        });

        setTerm(value);
        setCompanyFilterList(newCompanyList);
    };

    const handleDownloadExcel = (dataSource, sheetName, fileName, headerOrder = { header: [] }) => {
        const ws = utils.json_to_sheet(dataSource, headerOrder);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, sheetName);
        writeFileXLSX(wb, `${fileName}.xlsx`);
    };

    const downloadExcel = () => {
        if (companyFilterList.length > 0) {
            handleDownloadExcel(
                companyFilterList,
                "Company list",
                `STARCARD_CLUB_COMPANY_${dayjs().format("DD-MMM-YYYY")}`
            );
        } else {
            handleDownloadExcel(companyList, "Company list", `STARCARD_CLUB_COMPANY_${dayjs().format("DD-MMM-YYYY")}`);
        }
    };

    const downloadExcel2 = () => {
        handleDownloadExcel(
            redeemHistoryListExcel,
            "Redeem History",
            `STARCARD_CLUB_REDEEM_${dayjs().format("DD-MMM-YYYY")}`,
            {
                header: [
                    "create_timestamp",
                    "code",
                    "company_name",
                    "reward_name",
                    "reward_quantity",
                    "reward_point",
                    "name",
                ],
            }
        );
    };

    const onSortColumn = (selectedColumn, sortDirection, sortedRows) => {
        setCompanyFilterList(sortedRows);
    };

    const onSortColumn2 = (selectedColumn, sortDirection, sortedRows) => {
        if (sortDirection === "asc") {
            switch (selectedColumn.name) {
                case "วันเวลา":
                default:
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].create_timestamp, ["asc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["create_timestamp"], ["asc"]));
                    break;
                case "รหัสบริษัท":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].code, ["asc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["code"], ["asc"]));
                    break;
                case "ชื่อบริษัท":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].company_name, ["asc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["company_name"], ["asc"]));
                    break;
                case "รางวัลที่แลก":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].reward_name, ["asc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["reward_name"], ["asc"]));
                    break;
                case "จำนวน":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].reward_quantity, ["asc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["reward_quantity"], ["asc"]));
                    break;
                case "คำแนนที่ใช้":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].reward_point, ["asc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["reward_point"], ["asc"]));
                    break;
                case "Order ID":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].order_id, ["asc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["order_id"], ["asc"]));
                    break;
            }
        } else {
            switch (selectedColumn.name) {
                case "วันเวลา":
                default:
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].create_timestamp, ["desc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["create_timestamp"], ["desc"]));
                    break;
                case "รหัสบริษัท":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].code, ["desc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["code"], ["desc"]));
                    break;
                case "ชื่อบริษัท":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].company_name, ["desc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["company_name"], ["desc"]));
                    break;
                case "รางวัลที่แลก":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].reward_name, ["desc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["reward_name"], ["desc"]));
                    break;
                case "จำนวน":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].reward_quantity, ["desc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["reward_quantity"], ["desc"]));
                    break;
                case "คำแนนที่ใช้":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].reward_point, ["desc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["reward_point"], ["desc"]));
                    break;
                case "Order ID":
                    setRedeemHistoryList(_.orderBy(sortedRows, (arr) => arr[0].order_id, ["desc"]));
                    setRedeemHistoryListExcel((prev) => _.orderBy(prev, ["order_id"], ["desc"]));
                    break;
            }
        }

        // setRedeemHistoryList(sortedRows);
    };

    const onCalendarChange = (values) => {
        setRangeDate(values);
    };

    const onSearchHistory = () => {
        const startDate = rangeDate ? dayjs(rangeDate[0]).format("YYYY-MM-DD") : "";
        const endDate = rangeDate ? dayjs(rangeDate[1]).format("YYYY-MM-DD") : "";

        axios
            .get(`${baseURL}/shipping_history`, {
                headers: { Authorization: `Bearer ${liffToken}` },
                params: {
                    code_company: term2 || "",
                    startDate: startDate !== "Invalid Date" ? startDate : "",
                    endDate: endDate !== "Invalid Date" ? endDate : "",
                },
            })
            .then((res) => {
                if (res.data.Code === "20000") {
                    const newDatas = res.data.Data.map((item) => ({
                        ...item,
                        reward_quantity: item.reward_quantity.toFixed(2),
                        reward_point: item.reward_point.toFixed(2),
                    }));

                    const newDatasOrder = _.orderBy(newDatas, ["create_timestamp"], ["desc"]);
                    const newFormat = newDatasOrder.map((item) => ({
                        create_timestamp: item.create_timestamp,
                        code: item.code,
                        company_name: item.company_name,
                        reward_name: item.reward_name,
                        reward_quantity: item.reward_quantity,
                        reward_point: item.reward_point,
                        id: item.id,
                        state: item.state,
                        order_id: item.order_id,
                        name: item.address.name,
                    }));
                    setRedeemHistoryListExcel(newFormat);
                    const groupedData = _.groupBy(newDatasOrder, "id");
                    const result = _.values(groupedData);
                    const resultSorted = result
                        .map((data) => {
                            return data.map((d) => ({
                                ...d,
                                reward_point: parseFloat(d.reward_point, 2),
                            }));
                        })
                        .map((item) => (item.length > 1 ? _.orderBy(item, ["reward_point"], ["asc"]) : item))
                        .map((data) => {
                            return data.map((d) => ({
                                ...d,
                                reward_point: parseFloat(d.reward_point, 2).toFixed(2),
                                name: d.address.name,
                            }));
                        });

                    setRedeemHistoryList(resultSorted);
                } else {
                    setRedeemHistoryList([]);
                }

                setIsLoading(false);
            })
            .catch((e) => {
                throw new Error("Can't get company history", e);
            });
    };

    const onApproveClick = (data) => {
        setApproveData(data);
        setConfirmApproveDialog(true);
    };

    const onApproveRedeem = () => {
        if (inputApproveRef.current.value === verifyCode) {
            setVerifyCodeError("");
            const prepareData = approveData.map((item) => {
                return {
                    shipping_id: item.id,
                    state: "approve",
                };
            });

            axios
                .post(`${baseURL}/update_state_shipping`, prepareData, {
                    headers: {
                        Authorization: `Bearer ${liffToken}`,
                    },
                })
                .then((res) => {
                    if (res.data.Code !== "20000") {
                        setApproveErrorDialog(true);
                    }

                    setApproveSuccessDialog(true);
                    fetchShippingHistory();
                    onCloseApproveRedeem();
                })
                .catch((e) => {
                    onCloseApproveRedeem();
                    setApproveErrorDialog(true);
                    console.log("Can't approve: ", e);
                });
        } else {
            setVerifyCodeError("รหัสไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง");
        }
    };

    const makeCode = (length) => {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    };

    const onCloseApproveRedeem = () => {
        setVerifyCodeError("");
        setConfirmApproveDialog(false);
    };

    const fetchShippingHistory = () => {
        axios
            .get(`${baseURL}/shipping_history`, {
                headers: {
                    Authorization: `Bearer ${liffToken}`,
                },
            })
            .then((res) => {
                if (res.data.Code === "20000") {
                    const newDatas = res.data.Data.map((item) => ({
                        ...item,
                        reward_quantity: item.reward_quantity.toFixed(2),
                        reward_point: item.reward_point.toFixed(2),
                    }));

                    const newDatasOrder = _.orderBy(newDatas, ["create_timestamp"], ["desc"]);
                    const newFormat = newDatasOrder.map((item) => ({
                        create_timestamp: item.create_timestamp,
                        code: item.code,
                        company_name: item.company_name,
                        reward_name: item.reward_name,
                        reward_quantity: item.reward_quantity,
                        reward_point: item.reward_point,
                        id: item.id,
                        state: item.state,
                        order_id: item.order_id,
                        name: item.address.name,
                    }));
                    setRedeemHistoryListExcel(newFormat);
                    const groupedData = _.groupBy(newDatasOrder, "id");
                    const result = _.values(groupedData);
                    const resultSorted = result
                        .map((data) => {
                            return data.map((d) => ({
                                ...d,
                                reward_point: parseFloat(d.reward_point, 2),
                            }));
                        })
                        .map((item) => (item.length > 1 ? _.orderBy(item, ["reward_point"], ["asc"]) : item))
                        .map((data) => {
                            return data.map((d) => ({
                                ...d,
                                reward_point: parseFloat(d.reward_point, 2).toFixed(2),
                                name: d.address.name,
                            }));
                        });

                    setRedeemHistoryList(resultSorted);
                } else {
                    setRedeemHistoryList([]);
                }

                setIsLoading2(false);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const sendEvaluation = useCallback(
        async (userId) => {
            setSendEvaluateLoading(true);

            try {
                const response = await axios.post(
                    `${baseURL}/send_evaluation`,
                    { user_id: userId },
                    { headers: { Authorization: `Bearer ${liffToken}` } }
                );

                if (response) {
                    setSendEvaluateLoading(false);

                    if (response.data.Code === "20000") {
                        setSendEvaluateSuccess(true);
                    }
                }
            } catch (e) {
                setSendEvaluateSuccess(false);
                setSendEvaluateLoading(false);
                console.error(e);
            }
        },
        [liffToken]
    );

    const onSearchListEvaluation = useCallback(() => {
        setIsSearchEvaluation(true);

        const filtered = listEvaluation.filter((item) => {
            if (item.displayName) {
                return item.displayName.toLowerCase().includes(term3.toLowerCase());
            } else {
                return item.user_id.toLowerCase().includes(term3.toLowerCase());
            }
        });

        setListEvaluationFiltered(filtered);
    }, [listEvaluation, term3]);

    const onSearchListLineUser = useCallback(() => {
        setIsSearchLineUser(true);

        const filtered = listLineUser.filter((item) => {
            if (item.displayName) {
                return item.displayName.toLowerCase().includes(term3.toLowerCase());
            } else {
                return item.lineuserid.toLowerCase().includes(term3.toLowerCase());
            }
        });

        setListLineUserFiltered(filtered);
    }, [listLineUser, term3]);

    const fetchListEvaluation = useCallback(async () => {
        setIsLoading3(true);
        try {
            const response = await axios.get(`${baseURL}/list_evaluation`, {
                headers: {
                    Authorization: `Bearer ${liffToken}`,
                },
            });

            if (response) {
                setIsLoading3(false);
                setListEvaluation(response.data.Data);
            }
        } catch (e) {
            setIsLoading3(false);
            console.error(e);
        }
    }, [liffToken]);

    const fetchListLineUser = useCallback(async () => {
        try {
            const response = await axios.get(`${baseURL}/list_user`, {
                params: {
                    action: "query",
                },
                headers: {
                    Authorization: `Bearer ${liffToken}`,
                },
            });

            if (response) {
                setListLineUser(response.data.Data);
            }
        } catch (e) {
            console.error(e);
        }
    }, [liffToken]);

    useEffect(() => {
        if (liffToken) {
            fetchListEvaluation();
            fetchListLineUser();
        }
    }, [liffToken, fetchListEvaluation, fetchListLineUser]);

    useEffect(() => {
        if (confirmApproveDialog) {
            setVerifyCode(makeCode(5));
        }
    }, [confirmApproveDialog]);

    useEffect(() => {
        if (liffToken) {
            axios
                .get(`${baseURL}/company_list`, {
                    headers: {
                        Authorization: `Bearer ${liffToken}`,
                    },
                })
                .then((res) => {
                    if (res.data.Code === "20000") {
                        const dataLists = _.orderBy(res.data.Data, ["code"], ["asc"]);
                        setCompanyList(dataLists);
                    }

                    setIsLoading(false);
                })
                .catch((e) => {
                    throw new Error("Can't get company list", e);
                });

            if (tabActive === 2) {
                const result = rangeDate?.filter((item) => item);

                if (result?.length <= 1) {
                    fetchShippingHistory();
                }
            }
        }
        // eslint-disable-next-line
    }, [liffToken, tabActive]);

    useEffect(() => {
        if (!term3) {
            setIsSearchEvaluation(false);
            setIsSearchLineUser(false);
        }
    }, [term3]);

    return (
        <>
            <Helmet>
                <title>รายชื่อบริษัททั้งหมด | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                mx="auto"
                sx={{
                    minHeight: "100vh",
                    background: `url(${mainBgWhite}) no-repeat center center / cover`,
                    backgroundAttachment: "fixed",
                }}
            >
                <Box component="section" maxWidth="lg" mx="auto" sx={{ padding: "6rem 1.25rem" }}>
                    <Box
                        sx={{
                            width: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        }}
                    >
                        <Button
                            onClick={() => setTabActive(1)}
                            sx={{
                                color: tabActive === 1 ? "white" : "#002B38",
                                backgroundColor: tabActive === 1 ? "#002B38" : "white",
                                borderRadius: "0",
                                borderTopLeftRadius: ".5rem",
                                padding: ".75rem 2rem",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#002B38",
                                },
                            }}
                        >
                            รายชื่อบริษัททั้งหมด
                        </Button>
                        <Button
                            onClick={() => setTabActive(2)}
                            sx={{
                                color: tabActive === 2 ? "white" : "#002B38",
                                backgroundColor: tabActive === 2 ? "#002B38" : "white",
                                borderRadius: "0",
                                padding: ".75rem 2rem",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#002B38",
                                },
                            }}
                        >
                            ประวัติการแลกรางวัล
                        </Button>
                        <Button
                            onClick={() => setTabActive(3)}
                            sx={{
                                color: tabActive === 3 ? "white" : "#002B38",
                                backgroundColor: tabActive === 3 ? "#002B38" : "white",
                                borderRadius: "0",
                                borderTopRightRadius: ".5rem",
                                padding: ".75rem 2rem",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#002B38",
                                },
                            }}
                        >
                            ประเมินความพอใจ
                        </Button>
                    </Box>

                    <Box sx={{ display: tabActive === 1 ? "block" : "none" }}>
                        <Box
                            p={1}
                            sx={{
                                backgroundColor: "white",
                                borderBottomLeftRadius: "15px",
                                borderBottomRightRadius: "15px",
                                border: "2px solid white",
                                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={1} mb={2}>
                                <TextField
                                    fullWidth
                                    placeholder="ค้นหารหัสบริษัท, ชื่อบริษัท"
                                    value={term}
                                    onChange={onFilterCompanyList}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon sx={{ color: "#004B61" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        fontSize: "0.875rem",
                                        borderRadius: "15px",
                                        "& .MuiInputBase-root": {
                                            borderRadius: "15px",
                                            backgroundColor: "white",
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "0",
                                            height: "40px",
                                            color: "#004B61",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontSize: "0.875rem",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius: "15px",
                                            border: "2px solid #004B61",
                                        },
                                    }}
                                />
                                <Box
                                    sx={(theme) => ({
                                        width: "190px",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    })}
                                >
                                    <RedButton sx={{ fontSize: ".75rem" }} onClick={downloadExcel}>
                                        Export Excel
                                    </RedButton>
                                </Box>
                            </Box>
                            <DataTable
                                pagination
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[50, 100, 500]}
                                columns={columns}
                                data={companyFilterList.length > 0 ? companyFilterList : companyList}
                                customStyles={customStyles}
                                defaultSortFieldId={1}
                                progressPending={isLoading}
                                onRowClicked={onRowClicked}
                                onSort={onSortColumn}
                                progressComponent={
                                    <Box
                                        py={5}
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                }
                                noDataComponent={
                                    <Box
                                        py={5}
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                        }}
                                    >
                                        <Typography align="center" sx={{ fontSize: "1.25rem", color: "#004B61" }}>
                                            ไม่พบรายชื่อบริษัท
                                        </Typography>
                                    </Box>
                                }
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: tabActive === 2 ? "block" : "none" }}>
                        <Box
                            p={1}
                            sx={{
                                backgroundColor: "white",
                                borderBottomLeftRadius: "15px",
                                borderBottomRightRadius: "15px",
                                border: "2px solid white",
                                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={1} mb={2}>
                                <TextField
                                    fullWidth
                                    placeholder="ค้นหารหัสบริษัท"
                                    value={term2}
                                    onChange={(e) => setTerm2(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon sx={{ color: "#004B61" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        fontSize: "0.875rem",
                                        borderRadius: "15px",
                                        "& .MuiInputBase-root": {
                                            borderRadius: "15px",
                                            backgroundColor: "white",
                                        },
                                        "& .MuiInputBase-input": {
                                            padding: "0",
                                            height: "40px",
                                            color: "#004B61",
                                        },
                                        "& .MuiInputBase-input::placeholder": {
                                            fontSize: "0.875rem",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderRadius: "15px",
                                            border: "2px solid #004B61",
                                        },
                                    }}
                                />
                                <RedButton
                                    sx={{ width: "40%", maxWidth: "117px", fontSize: ".75rem" }}
                                    onClick={onSearchHistory}
                                >
                                    ค้นหา
                                </RedButton>
                            </Box>
                            <Box
                                pb={3}
                                display="flex"
                                justifyContent="space-between"
                                gap={2}
                                sx={{ position: "relative" }}
                            >
                                <RangePicker
                                    locale={locale}
                                    format="DD-MM-YYYY"
                                    className="myDateRange"
                                    value={rangeDate}
                                    onCalendarChange={onCalendarChange}
                                    getPopupContainer={(trigger) => trigger.parentElement}
                                    style={{ width: "100%", maxWidth: "350px", height: "40px" }}
                                />
                                <Box
                                    sx={(theme) => ({
                                        width: "190px",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                    })}
                                >
                                    <RedButton sx={{ fontSize: ".75rem" }} onClick={downloadExcel2}>
                                        Export Excel
                                    </RedButton>
                                </Box>
                            </Box>
                            <DataTable
                                pagination
                                paginationPerPage={50}
                                paginationRowsPerPageOptions={[50, 100, 500]}
                                columns={columns2}
                                data={redeemHistoryList}
                                customStyles={customStyles2}
                                defaultSortFieldId={1}
                                progressPending={isLoading2}
                                onSort={onSortColumn2}
                                defaultSortAsc={false}
                                progressComponent={
                                    <Box
                                        py={5}
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                }
                                noDataComponent={
                                    <Box
                                        py={5}
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                        }}
                                    >
                                        <Typography align="center" sx={{ fontSize: "1.25rem", color: "#004B61" }}>
                                            ไม่พบประวัติการแลกรางวัล
                                        </Typography>
                                    </Box>
                                }
                            />

                            <Dialog
                                open={confirmApproveDialog}
                                onClose={onCloseApproveRedeem}
                                sx={{
                                    "& .MuiPaper-root": {
                                        maxWidth: "unset",
                                    },
                                }}
                            >
                                <Paper
                                    sx={{
                                        minWidth: "850px",
                                        backgroundColor: "white",
                                        padding: "2rem",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "1rem",
                                            color: "#333",
                                            textAlign: "center",
                                            marginBottom: "1.25rem",
                                            whiteSpace: "pre-wrap",
                                        }}
                                    >
                                        ต้องการอนุมัติรายการแลกของรางวัล ใช่หรือไม่
                                    </Typography>

                                    <Paper
                                        elevation={0}
                                        sx={{
                                            borderTop: "1px solid rgba(224, 224, 224, 1)",
                                            borderLeft: "1px solid rgba(224, 224, 224, 1)",
                                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                                        }}
                                    >
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow
                                                        sx={{
                                                            background:
                                                                "linear-gradient(90deg, #002B38, #002B38, #002B38, #005D76, #006B7D)",
                                                        }}
                                                    >
                                                        <TableCell
                                                            sx={{
                                                                minWidth: "120px",
                                                                textAlign: "center",
                                                                color: "white",
                                                            }}
                                                        >
                                                            วันเวลา
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                minWidth: "100px",
                                                                textAlign: "center",
                                                                color: "white",
                                                            }}
                                                        >
                                                            รหัสบริษัท
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                minWidth: "150px",
                                                                textAlign: "center",
                                                                color: "white",
                                                            }}
                                                        >
                                                            ชื่อบริษัท
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                minWidth: "180px",
                                                                textAlign: "center",
                                                                color: "white",
                                                            }}
                                                        >
                                                            รางวัลที่แลก
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                minWidth: "100px",
                                                                textAlign: "center",
                                                                color: "white",
                                                            }}
                                                        >
                                                            จำนวน
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                minWidth: "100px",
                                                                textAlign: "center",
                                                                color: "white",
                                                            }}
                                                        >
                                                            คะแนนที่ใช้
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {approveData.map((item, idx) => (
                                                        <TableRow key={idx}>
                                                            <TableCell>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: ".75rem",
                                                                        color: "#333",
                                                                        textAlign: "center",
                                                                        marginBottom: "1.25rem",
                                                                        whiteSpace: "pre-wrap",
                                                                    }}
                                                                >
                                                                    {dayjs(item.create_timestamp).format(
                                                                        "DD/MM/YYYY HH:mm:ss"
                                                                    )}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: ".75rem",
                                                                        color: "#333",
                                                                        textAlign: "center",
                                                                        marginBottom: "1.25rem",
                                                                        whiteSpace: "pre-wrap",
                                                                    }}
                                                                >
                                                                    {item.code}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: ".75rem",
                                                                        color: "#333",
                                                                        textAlign: "center",
                                                                        marginBottom: "1.25rem",
                                                                        whiteSpace: "pre-wrap",
                                                                    }}
                                                                >
                                                                    {item.company_name}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: ".75rem",
                                                                        color: "#333",
                                                                        textAlign: "center",
                                                                        marginBottom: "1.25rem",
                                                                        whiteSpace: "pre-wrap",
                                                                    }}
                                                                >
                                                                    {item.reward_name}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: ".75rem",
                                                                        color: "#333",
                                                                        textAlign: "center",
                                                                        marginBottom: "1.25rem",
                                                                        whiteSpace: "pre-wrap",
                                                                    }}
                                                                >
                                                                    {item.reward_quantity}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: ".75rem",
                                                                        color: "#333",
                                                                        textAlign: "center",
                                                                        marginBottom: "1.25rem",
                                                                        whiteSpace: "pre-wrap",
                                                                    }}
                                                                >
                                                                    {item.reward_point}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>

                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "1.5rem",
                                                color: "#333",
                                                textAlign: "center",
                                                marginBottom: "1.25rem",
                                                whiteSpace: "pre-wrap",
                                                margin: "1rem auto",
                                            }}
                                        >
                                            {verifyCode}
                                        </Typography>
                                        <InputField
                                            inputRef={inputApproveRef}
                                            name="verify_code"
                                            placeholder="กรุณากรอกรหัสเพื่อยืนยัน"
                                            error={verifyCodeError ? true : false}
                                            inputProps={{
                                                maxLength: 8,
                                            }}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                margin: "1rem auto",
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: ".ึ5rem",
                                                textAlign: "center",
                                                color: "#ED1C24",
                                                whiteSpace: "pre-wrap",
                                                margin: "1rem auto 2rem",
                                            }}
                                        >
                                            {verifyCodeError}
                                        </Typography>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "0.5rem",
                                            maxWidth: "300px",
                                            margin: "0 auto",
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                fontSize: "1rem",
                                                color: "white",
                                                backgroundColor: "#ED1C24",
                                                padding: ".5rem 2rem",
                                                display: "block",
                                                margin: "0 auto",
                                                "&:hover": {
                                                    color: "white",
                                                    backgroundColor: "#ED1C24",
                                                    opacity: ".75",
                                                },
                                            }}
                                            onClick={onCloseApproveRedeem}
                                        >
                                            ยกเลิก
                                        </Button>
                                        <Button
                                            sx={{
                                                fontSize: "1rem",
                                                color: "white",
                                                backgroundColor: "#004B61",
                                                padding: ".5rem 2rem",
                                                display: "block",
                                                margin: "0 auto",
                                                "&:hover": {
                                                    color: "white",
                                                    backgroundColor: "#004B61",
                                                    opacity: ".75",
                                                },
                                            }}
                                            onClick={onApproveRedeem}
                                        >
                                            ตกลง
                                        </Button>
                                    </Box>
                                </Paper>
                            </Dialog>

                            <DialogInfo
                                isOpen={approveErrorDialog}
                                onClose={() => setApproveErrorDialog(false)}
                                text="เกิดข้อผิดพลาด ไม่สามารถอนุมัติรายการได้"
                                btnText="ปิด"
                            />

                            <DialogInfo
                                isOpen={approveSuccessDialog}
                                onClose={() => setApproveSuccessDialog(false)}
                                text="อนุมัติรายการสำเร็จ"
                                btnText="ปิด"
                                btnColor="#4BB543"
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: tabActive === 3 ? "block" : "none" }}>
                        {/* Tab in Evalution */}
                        <Box
                            sx={{
                                display: "flex",
                                padding: "1rem",
                                backgroundColor: "white",
                            }}
                        >
                            <Box
                                onClick={() => setTabActive3_in(1)}
                                sx={{
                                    margin: "0 0.5rem",
                                    padding: "1rem",
                                    fontSize: ".875rem",
                                    backgroundColor: tabActive3_in === 1 ? "#002B38" : "white",
                                    color: tabActive3_in === 1 ? "white" : "#002B38",
                                    border: "1px solid #002B38",
                                    cursor: "pointer",
                                }}
                            >
                                รายการประเมินความพึงพอใจ
                            </Box>
                            <Box
                                onClick={() => setTabActive3_in(2)}
                                sx={{
                                    margin: "0 0.5rem",
                                    padding: "1rem",
                                    fontSize: ".875rem",
                                    backgroundColor: tabActive3_in === 2 ? "#002B38" : "white",
                                    color: tabActive3_in === 2 ? "white" : "#002B38",
                                    border: "1px solid #002B38",
                                    cursor: "pointer",
                                }}
                            >
                                ส่งแบบประเมินความพึงพอใจ
                            </Box>
                        </Box>

                        {/* Tab 3.1 */}

                        {tabActive3_in === 1 && (
                            <Box
                                p={1}
                                sx={{
                                    backgroundColor: "white",
                                    borderBottomLeftRadius: "15px",
                                    borderBottomRightRadius: "15px",
                                    border: "2px solid white",
                                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                }}
                            >
                                <Box display="flex" alignItems="center" gap={1} mb={2}>
                                    <SearchField
                                        placeholder="ค้นหาชื่อไลน์"
                                        value={term3}
                                        onChange={(e) => setTerm3(e.target.value)}
                                    />
                                    <RedButton
                                        sx={{ width: "40%", maxWidth: "117px", height: "40px", fontSize: ".75rem" }}
                                        onClick={onSearchListEvaluation}
                                    >
                                        ค้นหา
                                    </RedButton>
                                </Box>
                                <DataTable
                                    pagination
                                    paginationPerPage={50}
                                    paginationRowsPerPageOptions={[50, 100, 500]}
                                    columns={columns3}
                                    data={isSearchEvaluation ? listEvaluationFiltered : listEvaluation}
                                    customStyles={customStyles3}
                                    progressPending={isLoading3}
                                    progressComponent={
                                        <Box
                                            py={5}
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    }
                                    noDataComponent={
                                        <Box
                                            py={5}
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                            }}
                                        >
                                            <Typography align="center" sx={{ fontSize: "1.25rem", color: "#004B61" }}>
                                                ไม่พบรายชื่อไลน์
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Box>
                        )}

                        {tabActive3_in === 2 && (
                            <Box
                                p={1}
                                sx={{
                                    backgroundColor: "white",
                                    borderBottomLeftRadius: "15px",
                                    borderBottomRightRadius: "15px",
                                    border: "2px solid white",
                                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                }}
                            >
                                <Box display="flex" alignItems="center" gap={1} mb={2}>
                                    <SearchField
                                        placeholder="ค้นหาชื่อไลน์"
                                        value={term3}
                                        onChange={(e) => setTerm3(e.target.value)}
                                    />
                                    <RedButton
                                        sx={{ width: "40%", maxWidth: "117px", height: "40px", fontSize: ".75rem" }}
                                        onClick={onSearchListLineUser}
                                    >
                                        ค้นหา
                                    </RedButton>
                                </Box>

                                {isSearchLineUser &&
                                    listLineUserFiltered.length > 0 &&
                                    listLineUserFiltered.map((item) => (
                                        <Box
                                            key={item._id}
                                            sx={{
                                                padding: ".5rem 0",
                                                borderBottom: "1px solid #EEE",
                                                "&:last-child": {
                                                    borderBottom: "none",
                                                },
                                            }}
                                        >
                                            <LineUserCard
                                                src={item.pictureUrl}
                                                name={item.displayName || item.lineuserid}
                                                onClick={() => sendEvaluation(item._id)}
                                            />
                                        </Box>
                                    ))}

                                {isSearchLineUser && term3 && listLineUserFiltered.length <= 0 && (
                                    <Box sx={{ fontSize: "1.25rem", textAlign: "center", padding: "1.5rem" }}>
                                        No data
                                    </Box>
                                )}

                                {!isSearchLineUser &&
                                    listLineUser.map((item) => (
                                        <Box
                                            key={item._id}
                                            sx={{
                                                padding: ".5rem 0",
                                                borderBottom: "1px solid #EEE",
                                                "&:last-child": {
                                                    borderBottom: "none",
                                                },
                                            }}
                                        >
                                            <LineUserCard
                                                src={item.pictureUrl || null}
                                                name={item.displayName || item.lineuserid}
                                                onClick={() => sendEvaluation(item._id)}
                                            />
                                        </Box>
                                    ))}

                                <DialogSuccess
                                    isOpen={sendEvaluateSuccess}
                                    onClose={() => setSendEvaluateSuccess(false)}
                                    text="ส่งแบบประเมินสำเร็จ"
                                    btnText="ปิด"
                                />

                                <DialogLoading open={sendEvaluateLoading} message="กำลังส่งแบบประเมิน" />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const SearchField = (props) => {
    const { value, onChange, placeholder } = props;

    return (
        <TextField
            fullWidth
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#004B61" }} />
                    </InputAdornment>
                ),
            }}
            sx={{
                fontSize: "0.875rem",
                borderRadius: "15px",
                "& .MuiInputBase-root": {
                    borderRadius: "15px",
                    backgroundColor: "white",
                },
                "& .MuiInputBase-input": {
                    padding: "0",
                    height: "40px",
                    color: "#004B61",
                },
                "& .MuiInputBase-input::placeholder": {
                    fontSize: "0.875rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "15px",
                    border: "2px solid #004B61",
                },
            }}
        />
    );
};

const LineUserCard = (props) => {
    const { src, name, onClick } = props;

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <ListItem alignItems="center" sx={{ paddingLeft: "0" }}>
                <ListItemAvatar>
                    <Avatar src={src} />
                </ListItemAvatar>
                <ListItemText primary={name} />
            </ListItem>
            <RedButton onClick={onClick} sx={{ width: "100%", maxWidth: "117px", fontSize: ".75rem" }}>
                ส่ง
            </RedButton>
        </Box>
    );
};

export default AdminPage;
