import { useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import caltex from "../../../assets/images/logo/main-logo.png";

const RewardItem = (props) => {
    const { id, images, point, name, unit, quantity } = props;
    const [imgError, setImgError] = useState(false);
    const imageRef = useRef();
    const baseImageURL = "https://media.starcardclub.com/caltex/reward";

    const onImageError = () => {
        setImgError(true);
        imageRef.current.src = caltex;
    };

    const onImageLoad = () => (imageRef.current.src === caltex ? setImgError(true) : setImgError(false));

    return (
        <Link to={`/reward/${id}`} style={{ textDecoration: "none" }}>
            <Grid
                width="100%"
                container
                pt={5}
                pb={2}
                px={2}
                mx="auto"
                sx={{
                    background: "linear-gradient(90deg, #E3EFFF, #FFFFFF)",
                    border: "2px solid white",
                    borderRadius: "10px",
                    position: "relative",
                }}
            >
                <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
                    <Box
                        sx={
                            imgError
                                ? { padding: "1.5rem .5rem", backgroundColor: "#002B38", borderRadius: "15px" }
                                : {
                                      display: "flex",
                                      alignItems: "center",
                                  }
                        }
                    >
                        <img
                            ref={imageRef}
                            src={`${baseImageURL}/${images[0]}`}
                            alt="Reward"
                            style={{ width: "90%", maxHeight: "160px" }}
                            onError={onImageError}
                            onLoad={onImageLoad}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{ fontSize: "1rem", fontWeight: "500", color: "#004B61" }}>แลกรับ</Typography>
                    <Typography
                        sx={{
                            fontSize: "1rem",
                            fontWeight: "500",
                            lineHeight: "1.5rem",
                            marginBottom: ".25rem",
                            color: "#FD0004",
                            wordWrap: "break-word",
                        }}
                    >
                        {name || "-"}
                    </Typography>
                    <Typography
                        sx={{ fontSize: "1rem", color: "#FD0004", wordWrap: "break-word", marginBottom: "40px" }}
                    >
                        {quantity} {unit}
                    </Typography>
                </Grid>
                <Box
                    sx={{
                        fontSize: "1.25rem",
                        fontWeight: "500",
                        color: "white",
                        backgroundColor: "#FD0004",
                        padding: "0 1rem",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        position: "absolute",
                        top: "0",
                        left: "20px",
                    }}
                >
                    {point || "-"} <span style={{ fontSize: "1rem" }}>คะแนน</span>
                </Box>
                <Box
                    sx={{
                        fontSize: ".85rem",
                        color: "#004B61",
                        padding: "0 1rem",
                        position: "absolute",
                        bottom: "20px",
                        right: "0",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    รายละเอียด <ArrowForwardIosIcon sx={{ fontSize: ".85rem", marginBottom: "-2px" }} />
                </Box>
            </Grid>
        </Link>
    );
};

export default RewardItem;
