import { useEffect, useState } from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { Box, CircularProgress, Grid, IconButton, TextField, Typography } from "@mui/material";
import DecreaseBtn from "../../components/ui/DecreaseBtn";
import IncreaseBtn from "../../components/ui/IncreaseBtn";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import { styled } from "@mui/system";

const RewardImg = styled("img")({
    display: "block",
    margin: "0 auto 10px",
    height: "70px",
});

const ShoppingCartItem = (props) => {
    const { setRedeemLists } = useAppContext();
    const [count, setCount] = useState(props.quantity || 1);

    const decreaseQty = () => {
        setCount((prev) => {
            if (+prev === 1) {
                return prev;
            } else {
                return +prev - 1;
            }
        });
    };

    const increaseQty = () => {
        setCount((prev) => {
            return +prev + 1;
        });
    };

    const onCountChange = (e) => {
        const value = e.target.value;
        if (!value) {
            setCount("");
        } else if (value === "0") {
            setCount(1);
        } else {
            setCount(+value);
        }
    };

    const onDeleteRedeem = () => {
        setRedeemLists((prevState) => {
            const duplicate = [...prevState]
            _.remove(duplicate, { reward_id: props.id });
            return duplicate;
        });
    };

    useEffect(() => {
        const targetItem = {
            reward_id: props.id,
            quantity: count,
        };

        setRedeemLists((prevState) => {
            let index = _.findIndex(prevState, { reward_id: targetItem.reward_id });

            if (index !== -1) {
                prevState.splice(index, 1, targetItem);
                return [...prevState];
            } else {
                return [...prevState, targetItem];
            }
        });
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, props.id]);

    return (
        <Box py={5} px={2} mb={2} sx={{ borderRadius: "10px", backgroundColor: "white", position: "relative" }}>
            <Grid container columnGap={1.5} justifyContent="space-between">
                {props.image && props.name && (
                    <Grid item xs={6} sm={8} alignSelf="center">
                        <RewardImg src={`https://media.starcardclub.com/caltex/reward/${props.image}`} />
                        <Typography align="center" sx={{}}>
                            {props.name}
                        </Typography>
                    </Grid>
                )}

                {!props.image && !props.name && <CircularProgress sx={{ display: "block", margin: "20px auto" }} />}

                <Grid item xs={5} sm={3} alignSelf="center">
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "5px" }}>
                        <DecreaseBtn onClick={decreaseQty} />
                        <Box sx={{ width: "calc(100% - 60px)" }}>
                            <TextField
                                fullWidth
                                value={count}
                                onChange={onCountChange}
                                inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                    autoComplete: "off",
                                    readOnly: true,
                                }}
                                sx={{
                                    fontSize: "0.875rem",
                                    "& .MuiInputBase-root": {
                                        borderRadius: "30px",
                                        backgroundColor: "white",
                                    },
                                    "& .MuiInputBase-input": {
                                        padding: "0 1.25rem",
                                        height: "35px",
                                        color: "#004B61",
                                        textAlign: "center",
                                    },
                                    "& .MuiOutlinedInput-root.Mui-disabled": {
                                        color: "#004B61",
                                        WebkitTextFillColor: "inherit",
                                    },
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "inherit",
                                    },
                                    "& .MuiInputBase-input::placeholder": {
                                        fontSize: "0.875rem",
                                    },
                                }}
                            />
                        </Box>
                        <IncreaseBtn onClick={increaseQty} />
                        <IconButton sx={{ position: "absolute", top: "0", right: "0" }} onClick={onDeleteRedeem}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ShoppingCartItem;
